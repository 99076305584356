<template>
  <div>
    <el-card >
      <el-table :data="drayBillSum" >
        <el-table-column prop="id" label="id" ></el-table-column>
        <el-table-column prop="bookId" :label="$t('msg.track.bookId')"></el-table-column>
        <el-table-column prop="ctnrId" :label="$t('msg.track.ctnrId')"></el-table-column>
        <el-table-column prop="srvStatus" label="srvStatus" ></el-table-column>
        <el-table-column prop="chargeType" label="chargeType" ></el-table-column>
        <el-table-column prop="billAmount" :label="$t('msg.account.Amount')"></el-table-column>
        <el-table-column prop="dueDate" :label="$t('msg.account.dueDate')"></el-table-column>
        <el-table-column prop="billStatus" :label="$t('msg.account.billStatus')"></el-table-column>
        <el-table-column prop="statementId" :label="$t('msg.account.statementId')"></el-table-column>
      </el-table>
    </el-card>
  </div>
</template>

<script>
import { defineComponent, onMounted, ref } from 'vue'
import axios from 'axios'
export default defineComponent({
  name: 'draybillsum',
  setup() {
    const drayBillSum = ref()
    onMounted(() => {
      axios.get(process.env.VUE_APP_BASE + '/getDrayBillSumList').then((response) => {
        drayBillSum.value = response.data
      })
    })
    return {
      drayBillSum
    }
  }
})
</script>
